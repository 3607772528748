import React from 'react'
import { Component } from 'react'
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import '../custom-components/scss/nav.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default () =>{
    const menuItems = [{link: '/', name: 'Home'}, {link:'/blog', name: 'Blog'},{link:'/contact',name:'About me'}]

        return (<div>

        <button onClick={()=>window.history.back()} className="fixed z-10 top-0 right-0 mt-5 mr-5 p-2">
        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 fa-2x hover:text-green-700"/>
        </button>
<div className="fixed flex items-center bg-gray-200 min-h-full min-w-full">
        <ul className=" grow p-6 text-center w-full  rounded">

        {menuItems.map(
                        menuItem => {
                            return(
                                <li>
                                    <AniLink swipe direction="down" to={menuItem.link} className="min-w-full block text-blue-700 hover:text-orange-500 text-3xl p-3 " key={menuItem.name}>
                                        {menuItem.name}
                                    </AniLink>
                                </li>
                            )
                        }
                    )}


            
        </ul>
    
</div>
</div>)
        }
    

